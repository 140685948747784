import React, { useState, useEffect } from "react";

const testimonials = [
  {
    "name": "Dylan S.",
    "title": "Software Engineer",
    "text": "LifeShack has been a complete game changer for me during my job search. With this incredible product, I no longer waste time manually entering repetitive information, allowing me to focus on interview preparation and maximizing my chances of success. I highly recommend this time-saving AI tool to anyone who wants to streamline their job application process.",
    "avatar": "/pictures/dylan.png"
  },
  {
    "name": "Emily R.",
    "title": "Marketing Specialist",
    "text": "I am thrilled with LifeShack's Auto-Apply feature. It has saved me so much time and energy. I love that I can apply to jobs with just one click. Before I found LifeShack, I felt like I should be constantly working on finding a job and was getting burnt out. Now I finally feel like I have an advantage in the job seeking battlefield.",
    "avatar": "/pictures/emily.png"
  },
  {
    "name": "Michael T.",
    "title": "Data Analyst",
    "text": "Honestly your product has been great, I have had a few phone calls and a few interviews, and it's the most traction I have had in my search. I just let your service do the heavy lifting and I am just focusing on upskilling and enjoying life.",
    "avatar": "/pictures/michael.png"
  },
  {
    "name": "Sophia L.",
    "title": "Graphic Designer",
    "text": "LifeShack has served as a personalized job-search assistant, streamlining the application process and alleviating considerable stress! Automating the job hunt has allowed me to concentrate more effectively on excelling in interviews. It has undeniably transformed and optimized my job search.",
    "avatar": "/pictures/sophia.png"
  },
  {
    "name": "James P.",
    "title": "Project Manager",
    "text": "I believe this is the best job search platform available right now. It works flawlessly, and the attention to detail is impressive. Definitely a game-changer.",
    "avatar": "/pictures/james.png"
  },
  {
    "name": "Olivia K.",
    "title": "Financial Analyst",
    "text": "Extremely easy to use and regularly updated. The platform is built for efficiency, making job searching effortless. Excited to see it continue evolving.",
    "avatar": "/pictures/olivia.png"
  },
  {
    "name": "Liam N.",
    "title": "Sales Executive",
    "text": "I can't praise LifeShack enough. It's feature-rich, well-designed, and makes everything seamless. Thank you for creating such a reliable platform.",
    "avatar": "/pictures/liam.png"
  },
  {
    "name": "Ava M.",
    "title": "HR Coordinator",
    "text": "One of the most feature-rich platforms I've used. Easy to set up and customize your job search. Highly recommend.",
    "avatar": "/pictures/ava.png"
  },
  {
    name: "Noah B.",
    title: "Software Developer",
    text: "Thank you, LifeShack. You helped me a lot. The platform is well-designed, easy to use, and makes job searching effortless. Highly recommended.",
      avatar: "/pictures/noah.png",
    },
    {
      name: "Zoe W.",
      title: "UX Designer",
      text: "LifeShack streamlined my job search like never before. The AI's precision in tailoring applications was impressive.",
      avatar: "/pictures/zoe.png",
    }
  ];
  

  
  const Testimonials = () => {
    const [paddingAmount, setPaddingAmount] = useState("0px");

    useEffect(() => {
      if (typeof window !== "undefined") {
        const updatePadding = () => {
          setPaddingAmount(window.innerWidth < 768 ? "0px" : "200px");
          console.log("Window width:", window.innerWidth);
        };
  
        updatePadding(); // Run once on mount
        window.addEventListener("resize", updatePadding);
        return () => window.removeEventListener("resize", updatePadding);
      }
    }, []);
    return (
      <div className="flex flex-col items-center py-16 px-4">
        <h2 className="text-3xl font-bold text-center" style={{ fontSize: "32px" }}>Testimonials</h2>
        <p className="text-gray-600 mb-12 text-center" style={{ fontSize: "16px" }}>
          Success stories from our satisfied users
        </p>
        <div style={{ paddingLeft: paddingAmount, paddingRight: paddingAmount, maxWidth: "1400px", margin: "0 auto" }}>
        <div className="grid grid-cols-2 gap-4 max-w-screen-lg mx-auto md:flex md:flex-wrap md:justify-center">

            {testimonials.map((testimonial, index) => (
                <div
                key={index}
                className="border rounded-lg p-4 flex flex-col justify-between bg-white shadow-lg w-full max-w-xs mx-auto"
                >
                <div className="flex flex-col mb-4">
                    <div className="flex items-center mb-2">
                    <span className="text-purple-600 text-lg">★★★★★</span>
                    </div>
                    <p className="text-sm text-gray-600 mb-6 h-24 overflow-hidden" style={{ fontSize: "16px" }}>
                    {testimonial.text}
                    </p>
                </div>
                <div className="flex items-center mt-auto">
                    <div className="w-16 h-16 flex-shrink-0 mr-4">
                    <img
                        src={testimonial.avatar}
                        alt={testimonial.name}
                        className="w-16 h-16 rounded-full object-cover"
                    />
                    </div>
                    <div style={{ lineHeight: "1.2", marginTop: "-4px" }}>
                    <p className="font-bold" style={{ fontSize: "20px", marginBottom: "2px" }}>{testimonial.name}</p>
                        <p className="text-gray-500" style={{ fontSize: "14px" }}>{testimonial.title}</p>
                    </div>
                </div>
                </div>
            ))}
            </div>
        </div>
      </div>
    );
  };
  
  export default Testimonials;
