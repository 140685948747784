import React, { useState, useEffect } from "react";
import NavBarLogo from "assets/home/navbar_logo_adjusted.svg";
import Link from "next/link";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Change when scrolled more than 50px
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure this runs only on the client
  
    const handleResize = () => {
      const isCurrentlyMobile = window.innerWidth <= 1000;
      if (isMenuOpen && !isCurrentlyMobile) {
        setIsMenuOpen(false);
      }
    };
  
    handleResize(); // Run once on mount
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]); // Dependency array ensures updates when `isMenuOpen` changes
  
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        textAlign: "center",
        borderRadius: "25px",
        margin: "0 auto",
        maxWidth: "1200px",
        padding: "10px 20px",
      }}
      className={styles.navbar}
    >
        <div
        style={{
            height: isScrolled ? "80px" : "auto",
        }}
        />
        <nav
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 2rem",
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: isMenuOpen ? "25px 25px 0px 0px" : "50px",
            border: "1px solid rgb(211, 218, 230)",
            transition: "all 0.3s ease-in-out",
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
            position: isScrolled ? "fixed" : "relative",
            top: "0",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: isScrolled ? "rgba(255, 255, 255, 0.95)" : "rgba(255, 255, 255, 1)",
            boxShadow: isScrolled ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none",
            zIndex: 1000,
          }}
          className={`${isScrolled ? styles.navbarScrolled : styles.navbarNotScrolled}`}
        >
        {/* Logo */}
        <a href="#hero" style={{ opacity: 1, display: "flex", alignItems: "center" }}>
          <NavBarLogo />
        </a>

        {/* Hamburger Menu for Mobile */}
          <button
            onClick={toggleMenu}
            className={styles.hamburger}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "1.5rem",
            }}
          >
            ☰
          </button>

        {/* Navigation Links */}
            <div
              className={`${styles.menu} ${styles.navLinks} ${isMenuOpen ? styles.navLinksMenuOpen : ""}`}
            style={{
                gap: "0.5rem",
                backgroundColor: "rgba(255, 255, 255, 1)",
                right: "auto",
                zIndex: 10,
                margin: 0,
            }}
            >
            <a
              onClick={(e) => {
                e.preventDefault();
                const target = document.getElementById("process");
                if (target) target.scrollIntoView({ behavior: "smooth" });
              }}
              style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem", cursor: "pointer" }}
            >
              Our Process
            </a>
            <a
              onClick={(e) => {
                e.preventDefault();
                const target = document.getElementById("plans");
                if (target) target.scrollIntoView({ behavior: "smooth" });
              }}
              style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem", cursor: "pointer" }}
            >
              Plans
            </a>
            <Link href="#testimonials">
            <a style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem" }}>
                Testimonials
              </a>
            </Link>
            <a
            href="/resources"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem", cursor: "pointer" }}
          >
            Blog
          </a>
          <a
            onClick={(e) => {
              e.preventDefault();
              const target = document.getElementById("faqs");
              if (target) target.scrollIntoView({ behavior: "smooth" });
            }}
            style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem", cursor: "pointer" }}
          >
            FAQ
          </a>
          <a href="/signin" style={{ textDecoration: "none", color: "inherit", padding: "10px", fontSize: "1.25rem" }}>
          Sign In
          </a>
            <a
                href="/signup"
                rel="noopener noreferrer"
                className={styles.hideDesktop}
                style={{
                  backgroundColor: "rgb(104, 91, 255)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "15px",
                  textDecoration: "none",
                  fontWeight: 700,
                  display: "block",
                  textAlign: "center",
                  marginTop: "10px",
                  width: "100%",
                }}
            >
                Sign Up
            </a>
          </div>

        {/* Call-to-Action Button */}
        <div className={styles.hideMobile}>
          <a
            href="/signup"
            rel="noopener noreferrer"
            style={{
              fontSize: "1.2rem", // Increase font size
              backgroundColor: "rgb(104, 91, 255)",
              color: "#fff",
              padding: "0.75rem 2rem", // Increase padding
              borderRadius: "60px",
              textDecoration: "none",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            Sign Up
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
