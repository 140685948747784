import React from "react";
import dynamic from "next/dynamic";
import styles from "./HighImpact.module.css";

// Dynamically import Lottie with SSR disabled
const Lottie = dynamic(() => import("react-lottie-player"), { ssr: false });

// Import your Lottie animation JSON files
import animation1 from "./animations/animation1.json";
import animation2 from "./animations/animation2.json";
import animation3 from "./animations/animation3.json";
import animation4 from "./animations/animation4.json";

const HighImpact = () => {
    const cards = [
        {
            title: "Automated Job Matching",
            text: "LifeShack's AI scans over 3 million jobs daily to find the best matches for your profile, ensuring you never miss an opportunity.",
            animation: animation1,
        },
        {
            title: "Personalized Application Materials",
            text: "Our AI crafts customized cover letters and tailors your resume for each application, helping you stand out to potential employers.",
            animation: animation2,
        },
        {
            title: "Time-Saving Automation",
            text: "By automating the job search and application process, LifeShack saves you over 95% of the time typically spent on job hunting.",
            animation: animation3,
        },
        {
            title: "Proven Success Stories",
            text: "Join thousands of users who have landed interviews and job offers through LifeShack's efficient and effective AI-driven approach.",
            animation: animation4,
        },
        ];
      

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "50px 20px",
      }}
    >
      <h2
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "120%",
          letterSpacing: "-0.02em",
          color: "rgb(2, 9, 21)",
          marginBottom: "20px",
        }}
      >
        High-Quality Applications at <span style={{ color: "rgb(104, 91, 255)" }}>Scale</span>
      </h2>

      <p
        style={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "1.5",
          color: "rgb(117, 123, 134)",
          maxWidth: "800px",
          marginBottom: "40px",
        }}
      >
        Unlock opportunities you were unaware of.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "30px",
          maxWidth: "1200px",
        }}
        className={styles.cardContainer}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            style={{
              width: "400px",
              borderRadius: "14px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Top Section with Background */}
            <div
            style={{
                background: "linear-gradient(to bottom, #a58cff, #ffffff)", // Purple to white gradient
                padding: "40px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            >

              <Lottie
                loop
                animationData={card.animation}
                play
                style={{ width: "150px", height: "150px" }}
              />
            </div>

            {/* Bottom Section with Text */}
            <div
              style={{
                padding: "20px",
              }}
            >
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "rgb(2, 9, 21)",
                  marginBottom: "10px",
                }}
              >
                {card.title}
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgb(117, 123, 134)",
                  lineHeight: "1.5",
                }}
              >
                {card.text}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "60px" }}>
        <a
          href="/signup"
          rel="noopener noreferrer"
          style={{
            backgroundColor: "rgb(104, 91, 255)",
            color: "#fff",
            padding: "12px 30px",
            borderRadius: "55px",
            textDecoration: "none",
            fontWeight: 700,
            textTransform: "uppercase",
            boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          Sign Up
        </a>
      </div>
    </section>
  );
};

export default HighImpact;
