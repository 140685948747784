import React from "react";

const CallToAction = () => {
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #6B46C1, #9F7AEA)", // Purple gradient
        color: "white",
        borderRadius: "20px",
        padding: "40px",
        textAlign: "center",
        maxWidth: "1000px", // Maximum width to center the content
        margin: "40px auto", // Center the section
        boxSizing: "border-box",
      }}
    >
      <h1 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "15px" }}>
        Unlock Your Career Potential!
      </h1>
      <p style={{ fontSize: "16px", marginBottom: "25px", maxWidth: "600px", margin: "25px auto" }}>
      Get started for free - no credit card required. See how we can accelerate your job search and land you more interviews with AI-powered applications.
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          paddingLeft: "20px", // Add padding to the sides
          paddingRight: "20px", // Add padding to the sides
        }}
      >
        <a href="/signup">
          <button
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "12px 30px",
              borderRadius: "50px",
              fontWeight: "bold",
              border: "none",
              cursor: "pointer",
            }}
          >
            SIGN UP
          </button>
        </a>
        {/* <button
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "12px 30px",
            borderRadius: "50px",
            fontWeight: "bold",
            border: "2px solid black",
            cursor: "pointer",
          }}
        >
          GET YOUR FREE GUIDE
        </button> */}
      </div>
    </div>
  );
};

export default CallToAction;
