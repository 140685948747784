import React, { useState, useEffect } from "react";
import Head from "next/head";
import Script from "next/script";
import Navbar from "../newcomponents/Navbar";
import HeroSection from "../newcomponents/HeroSection";
import ScrollingCompanies from "../newcomponents/ScrollingCompanies";
import DrivenByData from "../newcomponents/DrivenByData";
import HighImpact from "../newcomponents/HighImpact";
import OurProcess from "../newcomponents/OurProcess";
import MadeWithWindmill from "../newcomponents/MadeWithWindmill";
import PricingCards from "../newcomponents/PricingCards";
import Testimonials from "../newcomponents/Testimonials";
import FaqSimple from "../newcomponents/FaqSimple";
import CallToAction from "../newcomponents/CallToAction";
import Footer from "../newcomponents/Footer";
import styles from './style.module.css';
import { useAuth } from "@/src/auth";
import { useRouter } from "next/router";

const questionsAndAnswers = [
    {
        question: "How does LifeShack choose which jobs to apply to?",
        answer:
        "When setting up LifeShack, you specify the type of work you are seeking.\n\nWe then continuously perform a job search based on your criteria, and we evaluate each opportunity to ensure it is a job you're interested in.\n\nIf we determine there is a strong match, we complete your job application - factoring in your resume, work experience, and additional information.",
    },
    {
        question: "What types of jobs does LifeShack apply to?",
        answer:
        "We currently support full-time remote, hybrid, and onsite roles at some of the best companies in the world.\n\nOur system is growing every day to accommodate more geographies, industries, and part-time roles.",
    },
    {
        question: "Where do you find your jobs?",
        answer:
        "We source jobs directly from trusted companies to ensure high-quality applications. We are proud to support some of the best companies in the world.\n\nWe currently support over 100,000 companies worldwide.",
    },
    {
        question: "Can I customize my applications?",
        answer:
        "Yes, LifeShack customizes each application based on your resume, experience, and specific job requirements.\n\nYou can also adjust preferences in your account settings to tailor applications to your strengths.",
    },
    {
        question: "Does LifeShack apply to jobs automatically?",
        answer:
        "Yes. Once you've set up your preferences, LifeShack automatically applies to jobs that match your skills and interests.\n\nOur system ensures that each application is customized and relevant to increase your chances of getting hired.",
    },
    {
        question: "How many jobs does LifeShack apply to per month?",
        answer:
        "The number of applications depends on the plan you choose.\n\nOur Professional plan includes up to 50 applications per month, Ultimate allows 150, and Elite offers unlimited applications.",
    },
    {
        question: "Does LifeShack guarantee interviews or job offers?",
        answer:
        "We cannot guarantee interviews or job offers, but we maximize your chances by applying to high-quality, relevant opportunities with optimized applications.\n\nOur users consistently see increased interview invites compared to traditional job searching.",
    },
    {
        question: "How does LifeShack ensure applications stand out?",
        answer:
        "LifeShack uses AI to craft tailored cover letters and applications that highlight your strengths and experience.\n\nWe optimize each application based on the job description and hiring trends to increase response rates.",
    },
    {
        question: "Is LifeShack available for international job seekers?",
        answer:
        "Yes. We support applications for jobs in multiple countries and industries.\n\nOur coverage is continuously expanding to include more regions and job types.",
    }
];

const Newhome = () => {
  const { user } = useAuth();
  const router = useRouter();
  
  useEffect(() => {
    if (!user) return;

    router.push('/candidate/dashboard/auto/');
  }, [router, user]);

    return (
      <div className={styles.container}>
        <Head>
          <title>LifeShack | AI-Powered Job Search</title>
          <meta name="description" content="AI that applies to jobs automatically" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
  
        <Script
          async
          src="https://events.framer.com/script"
          data-fid="dd506d205a633c0145c791e4b280c4cf6e4cc77eba6928bd3c00aa4079c41273"
        />
  
        <div
            style={{
                minHeight: "auto",
                width: "95%",
                background: "linear-gradient(90deg, #e6e7ff 0%, white 50%, #e6e7ff 100%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                borderRadius: "25px",
                padding: "10px",
                boxSizing: "border-box",
                margin: "20px auto",
                marginBottom: "-20px",
                overflow: "hidden",
            }}
        >
        <Navbar />
        <HeroSection />
        </div>
        <ScrollingCompanies />
        <div
        style={{
            minHeight: "auto",
            width: "95%",
            background: "linear-gradient(90deg, white 0%, #e6e7ff 50%, white 100%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: "25px",
            padding: "80px",
            boxSizing: "border-box",
            margin: "20px auto",
            marginBottom: "0px",
            overflow: "hidden",
        }}
        >
        <DrivenByData />
        </div>
        <div id="process">
            <OurProcess />
        </div>
        <HighImpact />
        <div className={styles.wrapperWindmill} style={{padding: "50px" }}>
            <MadeWithWindmill />
        </div>
        <div
        style={{
            backgroundColor: "white", // Force white background
            paddingTop: "60px",
            paddingBottom: "15px",
        }}
        >
            <div id="plans">
                <PricingCards />
            </div>
        </div>
        <div
        style={{
            backgroundColor: "white", // Force white background
            paddingTop: "60px",
            paddingBottom: "15px",
        }}
        >
        <div id="testimonials">
            <Testimonials />
        </div>
        </div>
        <div
        style={{
            backgroundColor: "white", // Force white background
            paddingTop: "30px",
            paddingBottom: "30px",
        }}
        >
        </div>
        <div id="faqs">
            <FaqSimple questionsAndAnswers={questionsAndAnswers} noReadMore={true} />
        </div>
        <div
            style={{
                backgroundColor: "white", // Force white background
                paddingBottom: "15px",
            }}
            className={styles.callToActionContainer}
        >
            <div style ={{
                padding: "15px",
            }}>
                <CallToAction />
            </div>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default Newhome;
