import React, { useEffect, useState } from "react";
import { CheckCircleIcon } from "lucide-react";

const plans = [
  {
    title: "Professional",
    price: "$19/month",
    features: [
      "Custom tailored AI cover letters",
      "50 applications per month",
    ],
    buttonText: "Sign Up",
    highlight: false,
  },
  {
    title: "Ultimate",
    price: "$59/month",
    features: [
      "Custom tailored AI cover letters",
      "Custom tailored AI resumes",
      "150 applications per month",
      "PriorityApply",
    ],
    buttonText: "Sign Up",
    highlight: false,
  },
  {
    title: "Elite",
    price: "$99/month",
    features: [
      "Custom tailored AI cover letters",
      "Custom tailored AI resumes",
      "Unlimited applications",
      "Priority support",
      "Custom AI training",
      "PriorityApply",
    ],
    buttonText: "Sign Up",
    highlight: true,
  },
];

const PricingPlans = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [paddingAmount, setPaddingAmount] = useState("0px");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure code runs only on client

    const handleResize = () => {
    setIsMobile(window.innerWidth <= 1000);
    };

    handleResize(); // Run once to set initial state
    window.addEventListener("resize", handleResize);

    return () => {
    window.removeEventListener("resize", handleResize);
    };
}, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const updatePadding = () => {
        setPaddingAmount(window.innerWidth < 768 ? "0px" : "200px");
      };

      updatePadding(); // Run once on mount
      window.addEventListener("resize", updatePadding);
      return () => window.removeEventListener("resize", updatePadding);
    }
  }, []);

  return (
    <div className="flex flex-col items-center py-16 px-4">
    <h2 className="text-center font-extrabold" style={{ fontSize: "32px" }}>
        Plans for Everyone
    </h2>
    <p className="text-gray-600 mb-6 text-center" style={{ fontSize: "16px" }}>
        2 week free trial on all plans - no credit card required
      </p>
      <div style={{ paddingLeft: paddingAmount, paddingRight: paddingAmount, maxWidth: "1400px", margin: "0 auto" }}>
      <div className="flex flex-wrap justify-center gap-6 max-w-screen-lg mx-auto">
            {plans.map((plan, index) => (
            <div
                key={index}
                className="border rounded-lg p-8 flex flex-col justify-between w-80 transition duration-300"  // Increased width, padding
                style={{
                minHeight: isMobile ? "400px" : "auto",
                backgroundColor: plan.highlight ? "#6B46C1" : "#F7FAFC",
                color: plan.highlight ? "#FFFFFF" : "#1A202C",
                boxShadow:
                    hoveredIndex === index
                    ? plan.highlight
                    ? "0px 12px 25px rgba(0, 0, 0, 0.9)" // Gold glow for highlighted card
                    : "0px 12px 25px rgba(107, 70, 193, 0.9)" // Purple glow for normal cards
                    : "0px 8px 20px rgba(0, 0, 0, 0.1)", // Normal shadow
                borderRadius: "12px",
                transform: hoveredIndex === index ? "translateY(-3px)" : "none", // Slight lift on hover
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                >
                <div className="flex-grow">
                <h3 className="text-3xl font-bold mb-6">{plan.title}</h3> 
                <p className="text-2xl font-bold mb-6">{plan.price}</p> 
                <ul className="space-y-2 mb-8" style={{ fontSize: "16px" }}>
                    {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center">
                        <CheckCircleIcon className="mr-2" />
                        {feature}
                    </li>
                    ))}
                </ul>
                </div>
                <a href="/signup" style={{ textDecoration: "none", display: "block" }}>
                <button
                    className="w-full py-3 px-6 mt-6 font-bold transition duration-300"
                    style={{
                    backgroundColor: plan.highlight ? "#FFFFFF" : "#6B46C1",
                    color: plan.highlight ? "#6B46C1" : "#FFFFFF",
                    borderRadius: "50px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
                    fontSize: "24px",
                    transition: "box-shadow 0.3s ease-in-out, transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) => {
                    e.target.style.boxShadow = "0px 10px 30px rgba(0, 0, 0, 0.5)";
                    e.target.style.transform = "translateY(-2px)";
                    }}
                    onMouseLeave={(e) => {
                    e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.15)";
                    e.target.style.transform = "translateY(0)";
                    }}
                >
                    {plan.buttonText}
                </button>
                </a>
            </div>

            ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
