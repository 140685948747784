import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import NavBarLogo from "assets/home/navbar_logo_adjusted.svg";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure it runs only on the client

    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenSize(); // Run once on mount
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <footer
      style={{
        backgroundColor: "#001F3F", // Navy blue background
        color: "#FFFFFF", // White text
        padding: "20px 40px",
        textAlign: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          fontSize: "14px",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1200px",
          margin: "0 auto",
          marginTop: isMobile ? "22px" : "0px",
        }}
      >
        {/* Left: Logo */}
        <div style={{ display: isMobile ? "none" : "flex", alignItems: "center", marginBottom: isMobile ? "15px" : "0", borderBottom: isMobile ? "1px solid #555" : "none" }}>
          LifeShack
        </div>

        {/* Center: Navigation */}
        <nav
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "25px",
            textAlign: isMobile ? "center" : "left",
          }}
        >
        <a
        onClick={(e) => {
            e.preventDefault();
            const target = document.getElementById("process");
            if (target) target.scrollIntoView({ behavior: "smooth" });
        }}
        style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Our Process
        </a>
        <a
        onClick={(e) => {
            e.preventDefault();
            const target = document.getElementById("plans");
            if (target) target.scrollIntoView({ behavior: "smooth" });
        }}
        style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Plans
        </a>
        <a
        onClick={(e) => {
            e.preventDefault();
            const target = document.getElementById("testimonials");
            if (target) target.scrollIntoView({ behavior: "smooth" });
        }}
        style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Testimonials
        </a>
        <a
        onClick={(e) => {
            e.preventDefault();
            const target = document.getElementById("faqs");
            if (target) target.scrollIntoView({ behavior: "smooth" });
        }}
        style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        FAQ
        </a>
        <a
        href="mailto:hello@lifeshack.com"
        style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Contact Us
        </a>
        <a href="/terms" style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Terms
        </a>
        <a href="/privacy" style={{ textDecoration: "none",
          color: "#FFFFFF",
          fontWeight: "normal",
          fontSize: isMobile ? "18px" : "14px",
          cursor: "pointer" }}
        >
        Privacy
        </a>

        </nav>

        {/* Right: Icons */}
        <div style={{ display: "flex", gap: "10px", marginTop: isMobile ? "35px" : "0"}}>
        <a
            href="https://www.instagram.com/lifeshackofficial/"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon name="instagram" style={{ color: "#FFFFFF", fontSize: isMobile ? "30px" : "20px", cursor: "pointer" }} />
        </a>
        <a
            href="https://www.linkedin.com/company/lifeshack-jobs"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon name="linkedin" style={{ color: "#FFFFFF", fontSize: isMobile ? "30px" : "20px", cursor: "pointer" }} />
        </a>
        </div>
      </div>

        {/* Bottom Row */}
        <div
        style={{
            marginTop: "20px",
            borderTop: "1px solid #333",
            paddingTop: "10px",
            fontSize: "14px",
            color: "#AAAAAA",
            textAlign: "center",
        }}
        >
        © 2025 LifeShack. All rights reserved.
        </div>
    </footer>
  );
};

export default Footer;
