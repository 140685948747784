import React, { useState, useEffect, useRef } from "react";

const StatisticCard = ({ targetNumber, label }) => {
  const [count, setCount] = useState(0);
  const ref = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          let start = 0;
          const end = targetNumber;
          const duration = 1; // Faster animation (1 second)
          const stepSize =
            end >= 1000000
              ? 10000
              : end >= 100000
              ? 5000
              : end >= 10000
              ? 1000
              : 100;
          const stepTime = Math.max(10, Math.floor(duration / (end / stepSize)));

          const timer = setInterval(() => {
            start += stepSize;
            setCount(start);
            if (start >= end) {
              setCount(end);
              clearInterval(timer)
            }
          }, stepTime);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observerRef.current.observe(ref.current);
    }

    return () => {
      if (observerRef.current && ref.current) {
        observerRef.current.unobserve(ref.current);
      }
    };
  }, [targetNumber]);

  return (
    <div
      ref={ref}
      style={{
        flex: "1 1 350px",
        width: "275px",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "27px",
        border: "1px solid rgb(228, 233, 244)",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <h3
        style={{
          fontSize: "53px",
          fontWeight: "800",
          color: "rgb(104, 91, 255)",
          marginBottom: "10px",
        }}
      >
        {count >= 1000000
          ? `${(count / 1000000).toFixed(1)}M`
          : count >= 1000
          ? `${(count / 1000).toFixed(1)}K`
          : count}
      </h3>
      <p
        style={{
          fontSize: "20px",
          fontWeight: "500",
          color: "rgb(2, 9, 21)",
          lineHeight: "1.5",
        }}
      >
        {label}
      </p>
    </div>
  );
};

const DrivenByData = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (typeof window === "undefined") return; // Ensure code runs only on client

        const handleResize = () => {
        setIsMobile(window.innerWidth <= 1000);
        };

        handleResize(); // Run once to set initial state
        window.addEventListener("resize", handleResize);

        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "0px 20px",
      }}
    >
      {/* Heading */}
      <h2
        style={{
          fontSize: "32px",
          fontWeight: "700",
          lineHeight: "120%",
          letterSpacing: "-0.02em",
          color: "rgb(2, 9, 21)",
          marginBottom: "20px",
        }}
      >
        Driven by <span style={{ color: "rgb(104, 91, 255)" }}>Intelligence</span>, Proven
        by <span style={{ color: "rgb(104, 91, 255)" }}>Results</span>.
      </h2>

      {/* Tagline */}
      <p
        style={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "1.5",
          color: "rgb(18, 18, 18)",
          maxWidth: "800px",
          marginBottom: "40px",
        }}
      >
        Helping professionals land their next role faster.
      </p>

      {/* Statistics */}
      <div
        style={{
          display: "flex",
          justifyContent: isMobile ? "center" : "space-between",
          gap: "20px",
          maxWidth: "1200px",
          width: "100%",
          flexWrap: isMobile ? "wrap" : "nowrap",
          alignItems: isMobile ? "stretch" : "normal",
          overflowX: isMobile ? "hidden" : "auto",
        }}
      >
        <StatisticCard targetNumber={3150000} label="Job Opportunities" />
        <StatisticCard targetNumber={1200000} label="Applications Submitted" />
        <StatisticCard targetNumber={792000} label="Hours Saved" />
      </div>
    </section>
  );
};

export default DrivenByData;
