import { useState } from "react";
import { Icon } from "semantic-ui-react";
import Link from "next/link";

const FaqSimple = ({ questionsAndAnswers, noReadMore }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const toggleActiveIndex = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto", padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", fontSize: "32px", marginBottom: "20px", fontWeight: "bold" }}>
        Frequently Asked Questions
      </h1>

      {questionsAndAnswers.map((item, index) => (
        <div
          key={index}
          onClick={() => toggleActiveIndex(index)}
          style={{
            borderBottom: "1px solid #e0e0e0",
            padding: "25px 0",
            cursor: "pointer",
            transition: "background-color 0.3s",
            backgroundColor: activeIndex === index ? "#f9f9f9" : "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {item.question}
            <ChevronIcon isOpen={activeIndex === index} />
          </div>
          {activeIndex === index && (
            <div style={{ marginTop: "20px", fontSize: "16px", color: "#555" }}>{item.answer}</div>
          )}
        </div>
      ))}

      {!noReadMore && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Link href="/help/categories/faqs">
            <a style={{ color: "#0070f3", textDecoration: "none", fontSize: "13px" }}>Read more FAQs</a>
          </Link>
        </div>
      )}
    </div>
  );
};

const ChevronIcon = ({ isOpen }) => (
  <div style={{ marginLeft: "10px" }}>
    <Icon name={isOpen ? "chevron up" : "chevron down"} size="small" />
  </div>
);

export default FaqSimple;
