import React, { useState, useEffect } from "react";
import "react-vertical-timeline-component/style.min.css";
import { FaHandshake, FaSearch, FaChartLine } from "react-icons/fa";
import ScrollBar from "./ScrollBar/ScrollBar";

const OurProcess = () => {
const steps = [
    {
        title: "Job Preferences & Profile Setup",
        text: "You tell us your desired roles, industries, and locations. We optimize your resume and cover letter for each job to align with high-demand opportunities.",
        icon: <FaHandshake />,
    },
    {
        title: "Automated Job Search & Applications",
        text: "Our AI scans thousands of job postings daily and submits tailored applications on your behalf—maximizing your chances without the manual effort.",
        icon: <FaSearch />,
    },
    {
        title: "Interview Scheduling & Follow-Ups",
        text: "We track your applications ensuring a seamless job-hunting experience.",
        icon: <FaChartLine />,
    },
    ];
      

  const [scrollProgress, setScrollProgress] = useState(0);
  const sectionRef = React.useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const start = rect.top - windowHeight * 0.5; // Start animation when halfway into view
        const end = rect.bottom - windowHeight * 0.3; // End animation when near bottom
  
        const progress = Math.min(Math.max((windowHeight - start) / (end - start), 0), 1);
        setScrollProgress(progress);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section
      ref={sectionRef}
      style={{
        textAlign: "center",
        background: "linear-gradient(180deg, #ffffff, #f9f9ff)",
        padding: "50px 20px",
      }}
    >
      <h2 style={{ fontSize: "36px", fontWeight: "700", marginBottom: "10px" }}>
        Our Process, Your <span style={{ color: "rgb(104, 91, 255)" }}>Success</span>
      </h2>
      <p style={{ fontSize: "18px", color: "rgb(117, 123, 134)", marginBottom: "40px" }}>
        Our process is designed to land you opportunities, <em>fast</em>.
      </p>

      <div style={{ position: "relative" }}>
        <ScrollBar />
      </div>
    </section>
  );
};

export default OurProcess;
