import React, { useState, useEffect } from "react";
import Slider from 'react-infinite-logo-slider';

const companyLogos = [
  {
    name: 'Google',
    url: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg',
  },
  {
    name: 'Amazon',
    url: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg',
  },
  {
    name: 'Microsoft',
    url: 'https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg',
  },
  {
    name: 'Apple',
    url: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
  },
  {
    name: 'Tesla',
    url: 'https://upload.wikimedia.org/wikipedia/commons/b/bd/Tesla_Motors.svg',
  },
  {
    name: 'Meta',
    url: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Meta_Platforms_Inc._logo.svg',
  },
  {
    name: 'Netflix',
    url: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg',
  },
  {
    name: 'OpenAI',
    url: 'https://upload.wikimedia.org/wikipedia/commons/9/97/OpenAI_logo_2025.svg',
  },
  {
    name: 'Adobe',
    url: 'https://upload.wikimedia.org/wikipedia/commons/6/6e/Adobe_Corporate_logo.svg',
  },
  {
    name: 'IBM',
    url: 'https://upload.wikimedia.org/wikipedia/commons/5/51/IBM_logo.svg',
  },
  {
    name: 'Salesforce',
    url: 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg',
  },
  {
    name: 'Nike',
    url: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_NIKE.svg',
  },
];

const ScrollingCompanies = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <section
    style={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center',
      textAlign: isMobile ? 'center' : 'left',
      padding: '50px 50px',
      paddingBottom: '10px',
      boxSizing: 'border-box',
      width: '100%',
      margin: '0 auto',
    }}
  >
    {/* Headline */}
    <div
      style={{
        flex: '0 1 auto', // Forces it to take up space but not grow infinitely
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: isMobile ? 'center' : 'left',
        marginRight: isMobile ? '0' : '20px',
        borderRight: isMobile ? 'none' : '2px solid #d8d8d8',
        marginBottom: isMobile ? '20px' : '0',
        paddingRight: isMobile ? '0' : '20px',
        minWidth: '200px', // Ensures it doesn't collapse
        whiteSpace: 'nowrap', // Prevents text from breaking onto new lines
        flexShrink: 0, // Prevents disappearing
      }}
    >
      <h2
        style={{
          fontSize: '20px',
          fontWeight: '700',
          color: 'rgb(13, 13, 13)',
          margin: 0,
        }}
      >
        Land your dream job
      </h2>
    </div>

    {/* Scrolling Logos */}
    <div
      style={{
        flex: '1',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
    >
      <Slider
        width="200px"
        duration={50}
        pauseOnHover={false}
        blurBorders={false}
      >
        {companyLogos.map((company, index) => (
          <Slider.Slide key={index}>
            <div style={{ width: '120px', display: 'flex', justifyContent: 'center' }}>
              <img
                src={company.url}
                alt={`${company.name} logo`}
                style={{
                  height: '50px',
                  objectFit: 'contain',
                  filter: 'grayscale(100%)',
                  opacity: '0.8',
                }}
              />
            </div>
          </Slider.Slide>
        ))}
      </Slider>
    </div>
  </section>

  );
};

export default ScrollingCompanies;
