import React, { useRef, useState, useEffect } from "react";

function useOnScreen(ref, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]);

  return isIntersecting;
}

const MadeWithWindmill = () => {
  
  const videos = Array(9).fill().map((_, i) => ({
    src: `/videos/video_${i < 7 ? i + 1 : i + 2}.mp4`
  }));

  const columns = [0, 1, 2, 3].map((colIndex) =>
    videos.filter((_, i) => i % 4 === colIndex)
  );

  const text = "Job Search Made Easy";

  const textOverlayRef = useRef(null);
  const isVisible = useOnScreen(textOverlayRef, "0px");

  // State to manage the animation play state for each column
  const [isRunning, setIsRunning] = useState(
    new Array(columns.length).fill(true)
  );

  useEffect(() => {
    // Update the animationPlayState when `isRunning` changes
    columns.forEach((_, colIndex) => {
      const columnElement = document.querySelector(
        `.column-${colIndex} .scrolling-content`
      );
      if (columnElement) {
        columnElement.style.animationPlayState = isRunning[colIndex]
          ? "running"
          : "paused";
      }
    });
  }, [isRunning, columns]);

  const handleMouseEnter = (colIndex) => {
    setIsRunning((prev) => {
      const updatedStates = [...prev];
      updatedStates[colIndex] = false; // Pause only this column
      return updatedStates;
    });
  };
  
  const handleMouseLeave = (colIndex) => {
    setIsRunning((prev) => {
      const updatedStates = [...prev];
      updatedStates[colIndex] = true; // Resume only this column
      return updatedStates;
    });
  };
  

  return (
    <section
      style={{
        position: "relative",
        height: "100vh",
        background: "black",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
      }}
    >
      <div
        style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: "30px",
            padding: "30px", 
            boxSizing: "border-box",
        }}
      >
        {columns.map((columnVideos, colIndex) => {
          const direction = colIndex % 2 === 0 ? "up" : "down";
          return (
            <div
              key={colIndex}
              className={`column-${colIndex}`}
              style={{
                position: "relative",
                overflow: "hidden",
                height: "100%",
              }}
              onMouseEnter={() => handleMouseEnter(colIndex)}
              onMouseLeave={() => handleMouseLeave(colIndex)}
            >
            <div
            className="scrolling-content"
            style={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                animation: `${direction === "up" ? "scrollUp" : "scrollDown"} 200s linear infinite`,
                willChange: "transform",
            }}
            >
                {[...columnVideos, ...columnVideos].map((video, index) => (
                    <video
                    key={index}
                    src={video.src}
                    style={{
                        position: "relative",
                        overflow: "hidden",
                        height: "100%",
                        width: "100%",
                        borderRadius: "25px",
                        opacity: 0.5, // Default dimmed state
                        transition: "opacity 0.3s ease-in-out",
                    }}
                    loop
                    muted
                    playsInline
                    autoPlay={false}
                    onMouseOver={(e) => {
                        e.target.muted = true;
                        e.target.play();
                        e.target.style.opacity = 1; // Highlight the playing video
                    }}
                    onMouseOut={(e) => {
                        e.target.muted = true;
                        e.target.pause();
                        e.target.style.opacity = 0.5; // Dim when not playing
                    }}
                    />
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="text-overlay" ref={textOverlayRef}>
        {text.split("").map((char, index) => (
          <span
            key={index}
            className={`text-letter ${isVisible ? "animate" : ""}`}
            style={{ animationDelay: `${index * 0.1}s` }}
          >
            {char}
          </span>
        ))}
      </div>
      <style>
        {`
            @keyframes scrollUp {
            0% { transform: translateY(0); }
            100% { transform: translateY(-50%); }
            }

            @keyframes scrollDown {
            0% { transform: translateY(-50%); }
            100% { transform: translateY(0); }
            }
          .text-overlay {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 48px;
            font-weight: bold;
            text-shadow: 0 2px 4px rgba(0,0,0,0.5);
            white-space: pre;
            line-height: 1.2;
          }
          .text-letter {
            display: inline-block;
            opacity: 0;
          }
          .text-letter.animate {
            animation: fadeInLetter 0.5s ease forwards;
          }
          @keyframes fadeInLetter {
            0% {
              opacity: 0;
              transform: translateY(20px);
            }
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </section>
  );
};

export default MadeWithWindmill;
