import styles from "./style.module.css";
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Briefcase, CheckCircle, Timer } from 'lucide-react';

const DashboardAnalytics = () => {
  const [isAutoApplyActive, setIsAutoApplyActive] = React.useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [screenWidth, setScreenWidth] = useState(typeof window !== "undefined" ? window.innerWidth : 0);
  
  useEffect(() => {
    if (typeof window === "undefined") return;
  
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 1350);
    };
  
    handleResize(); // Run on mount
    window.addEventListener("resize", handleResize);
  
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getLastSevenDays = () => {
    const baseData = [
      { jobsEvaluated: 45, jobsQueued: 12, timesSaved: 8 },
      { jobsEvaluated: 52, jobsQueued: 15, timesSaved: 9 },
      { jobsEvaluated: 48, jobsQueued: 10, timesSaved: 7 },
      { jobsEvaluated: 70, jobsQueued: 18, timesSaved: 12 },
      { jobsEvaluated: 65, jobsQueued: 14, timesSaved: 10 },
      { jobsEvaluated: 75, jobsQueued: 20, timesSaved: 13 },
      { jobsEvaluated: 80, jobsQueued: 22, timesSaved: 15 },
    ];
  
    return baseData.map((data, index) => {
      const date = new Date();
      date.setDate(date.getDate() - (6 - index));
      return {
        date: date.toLocaleDateString("en-US", { month: "short", day: "numeric" }),
        ...data,
      };
    });
  };
  
  const [analyticsData, setAnalyticsData] = useState(getLastSevenDays());
  
  useEffect(() => {
    setAnalyticsData(getLastSevenDays());
  }, []);

  useEffect(() => {
      if (typeof window === "undefined") return; // Ensure it runs only on the client

      const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 1350);
      };

      checkScreenSize(); // Run once on mount
      window.addEventListener("resize", checkScreenSize);

      return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const metrics = [
    {
      icon: <Briefcase className="w-5 h-5 text-white" />,
      label: "Jobs Queued",
      value: "87",
      trend: "+22"
    },
    {
      icon: <CheckCircle className="w-5 h-5 text-white" />,
      label: "Apps Submitted",
      value: 260,
      trend: "30 days"
    },
    {
      icon: <CheckCircle className="w-5 h-5 text-white" />,
      label: "Jobs Evaluated",
      value: "3405",
      trend: "7 days"
    },
    {
      icon: <Timer className="w-5 h-5 text-white" />,
      label: "Time Saved",
      value: "171.6 hrs",
      trend: "30 days"
    }
  ];

  return (
    <div
      className={styles.mainCard}
      style={{
        maxWidth: "100%",
        margin: "0 auto",
        padding: "20px",
      }}
    >
      <div className="rounded-xl text-white">
          <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
              <div className={`w-3 h-3 rounded-full ${isAutoApplyActive ? 'bg-green-400 animate-pulse' : 'bg-gray-400'}`}></div>
          </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8 mt-6">
            {metrics.map((metric, index) => (
              <div key={index} className="bg-white/10 rounded-lg p-3">
                <div className="flex items-center gap-2 mb-1">
                  {metric.icon}
                  <span className="text-sm font-medium">{metric.label}</span>
                </div>
                <div className="flex flex-col text-right md:text-left">
                  <span className="text-xl font-bold">{metric.value}</span>
                  <span className="text-xs text-indigo-200 mt-1">{metric.trend}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-white/20 rounded-lg p-6 h-48" style={{ marginBottom: "5px"}}>
            <h3 style={{ position: "relative", top: "-7px", margin: "auto", width: isMobile ? `${screenWidth-150}px` : "100%" }} className="text-sm font-semibold mb-2">Applications Submitted</h3>
            <ResponsiveContainer width="100%" height="100%" style={{ position: "relative", left: "-25px", top: "10px" }}>
                <LineChart data={analyticsData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#6366f1" />
                <XAxis dataKey="date" stroke="#e0e7ff" tick={{fontSize: 12}} />
                <YAxis stroke="#e0e7ff" tick={{fontSize: 12}} />
                <Tooltip 
                    contentStyle={{ 
                      backgroundColor: '#4f46e5',
                      border: 'none',
                      borderRadius: '8px',
                      padding: '8px'
                    }}
                />
                <Line 
                    type="monotone" 
                    dataKey="jobsEvaluated" 
                    stroke="#ffffff" 
                    strokeWidth={2}
                    name="Applications Submitted"
                />
                </LineChart>
            </ResponsiveContainer>
          </div>
          <p className="text-xs text-gray-300 text-center mt-4">
            *This data reflects a typical user on the platform.
          </p>
      </div>
  </div>
  );
};

export default DashboardAnalytics;
