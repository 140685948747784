import WidgetHome from "@/components/WidgetHome";
import React from "react";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <section
      id="hero"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "left",
        boxSizing: "border-box",
        width: "100%",
        maxWidth: "1100px",
        margin: "0 auto",
      }}
      className={styles.heroSection}
    >
      {/* Text Section */}
      <div
        style={{
          flex: "1",
        }}
        className={styles.heroText}
      >
        <h1
          style={{
            fontSize: "36px",
            fontWeight: 500,
            lineHeight: "1.1",
            color: "rgb(2, 9, 21)",
            marginBottom: "20px",
          }}
        >
          Stop applying. <span style={{ color: "rgb(104, 91, 255)", fontWeight: "700" }}>Start interviewing.</span>
        </h1>

        <p
          style={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "1.4",
            letterSpacing: "-0.02em",
            color: "rgb(2, 9, 21)",
            marginBottom: "30px",
          }}
        >
          Let LifeShack handle the entire job search process for you. We
          find the best opportunities, tailor your resume and cover
          letter, and apply to jobs for you — until you're hired.
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "15px",
            width: "100%",
          }}
          className={styles.heroButtons}
        >
          <a
            href="/signup"
            style={{
              backgroundColor: "rgb(104, 91, 255)",
              color: "#fff",
              padding: "12px 30px",
              borderRadius: "55px",
              textDecoration: "none",
              fontWeight: 700,
              textTransform: "uppercase",
              boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            Get started for free
          </a>

          <p
            style={{
              fontSize: "18px",
              color: "rgb(2, 9, 21)",
            }}
          >
            Trusted by 50,000+ job seekers
          </p>
        </div>
      </div>

      {/* Video Section */}
      <div
        className={styles.heroVideo}
      >
        <WidgetHome />
        </div>
    </section>
  );
};

export default HeroSection;
