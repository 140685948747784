import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import styles from './style.module.css';
import _ from 'lodash';


const VerticalProgress = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  const stickyRef = useRef(null);
  const animationFrameRef = useRef(null);

  // Create a memoized scroll handler using useCallback
  const handleScroll = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    animationFrameRef.current = requestAnimationFrame(() => {
      const container = containerRef.current;
      const sticky = stickyRef.current;

      if (container && sticky) {
        const containerRect = container.getBoundingClientRect();
        const stickyRect = sticky.getBoundingClientRect();
        
        const relativePosition = stickyRect.top - containerRect.top;
        const percentage = Math.min(
          Math.max(
            (relativePosition / (containerRect.height - stickyRect.height)) * 100,
            0
          ),
          100
        );
        
        setScrollProgress(Math.round(percentage));
      }
    });
  }, []);

  // Debounced resize handler
  const handleResize = useCallback(
    _.debounce(() => {
      const container = containerRef.current;
      if (container) {
        setContainerHeight(container.offsetHeight);
      }
    }, 250),
    []
  );

  useEffect(() => {
    // Initial container height
    handleResize();

    // Add event listeners
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleResize);
    
    // Initial calculation
    handleScroll();

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [handleScroll, handleResize]);

  return (
    <>
      <div className={styles.desktop}>
        <div ref={containerRef} className={styles.container}>
          <div 
            className={styles.progressFill} 
            style={{ 
              height: `${scrollProgress}%`,
              transform: `translateZ(0)` // Force GPU acceleration
            }} 
          >
            <div className={`${styles.stage} ${scrollProgress > 0 ? styles.visible : ''}`}>
              {
                scrollProgress < 33 && (
                  <span>
                    Step 1
                  </span>
                )
              }
              {
                scrollProgress >= 33 && scrollProgress < 60 && (
                  <span>
                    Step 2
                  </span>
                )
              }
              {
                scrollProgress >= 60 && scrollProgress < 100 && (
                  <span>
                    Step 3
                  </span>
                )
              }
              {
                scrollProgress === 100 && (
                  <span>
                    🚀
                  </span>
                )
              }
            </div>
          </div>
          <div ref={stickyRef} className={styles.progress} />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.leftContent}>
          {
            scrollProgress < 33 && (
              <SomeComponent
                isVisible={scrollProgress > 0 && scrollProgress < 30}
                header={'We search for jobs daily and find your best matches.'}
                lottieSource='../search.lottie'
              />
            )
          }                     
          {
            scrollProgress > 50 && (
              <SomeComponent
                isVisible={scrollProgress >= 60}
                header={'We submit applications and track everything for full visibility and transparency.'}
                lottieSource='../dashboard.lottie'
              />
            )
          }            
          </div>

          <div className={styles.rightContent}>
          {
            scrollProgress > 30 && scrollProgress < 60 && (
              <SomeComponent
                isVisible={scrollProgress >= 31 && scrollProgress < 60}
                header={'We tailor your resume to highlight your relevant experience — and draft authentic cover letters that tell your story.'}
                lottieSource='../writing.lottie'
              />
            )
          }    
          </div>        
        </div>
      </div>
      <div className={styles.mobile}>
        <SomeComponent
          isVisible={true}
          notSticky={true}
          header={'We search for jobs daily and find your best matches.'}
          lottieSource='../search.lottie'
        />
        <SomeComponent
          isVisible={true}
          notSticky={true}
          header={'We tailor your resume to highlight your relevant experience — and draft authentic cover letters that tell your story.'}
          lottieSource='../writing.lottie'
        />
        <SomeComponent
          isVisible={true}
          notSticky={true}
          header={'We submit applications and track everything for full visibility and transparency.'}
          lottieSource='../dashboard.lottie'
        />   
      </div>
    </>
  );
};

export default VerticalProgress;


function SomeComponent({ isVisible, notSticky, lottieSource = "../animation.lottie", header = "Lottie Animation" }) {
  return (
    <div className={styles.lottieComponent} style={{ opacity: isVisible ? 1 : 0, width: "300px", height: "300px", marginBottom: "80px", position: notSticky ? "initial" : "sticky" }}>
      <DotLottieReact
        src={lottieSource}
        loop
        width={300}
        height={300}
        autoplay
      />
      <p style={{ lineHeight: "1.4", fontSize: "18px" }}>{header}</p>
    </div>
  )
}